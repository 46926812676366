import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { JogosAlunosDetalhesModel, JogosAlunosModel } from '../jogos/jogosModel';
import { ResponseModel } from '../login/loginModel';

@Injectable()
export class AulasService {

  Rota: String = this.globalFunc.APIUrl() + "/apiftp";

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  GetAllJogosAluno(idusuarios: number) {
    return this.http.get<JogosAlunosDetalhesModel[]>(`${this.Rota}/GetAllJogosAluno/${idusuarios}`)
      .toPromise()
      .then(response => <JogosAlunosDetalhesModel[]>response)
      .then(data => {
        return <JogosAlunosDetalhesModel[]>data['response'];
      })
  }

  CriarJogoAluno(formJogo: JogosAlunosModel) {
    return this.http.post<ResponseModel>(`${this.Rota}/CriarJogoAluno`, formJogo)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
      return <ResponseModel>data;
    });
  }

  AtualizarJogoAluno(formJogo: JogosAlunosModel) {
    return this.http.patch<ResponseModel>(`${this.Rota}/AtualizarJogoAluno`, formJogo)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
      return <ResponseModel>data;
    });
  }


}