export class UsuarioModel {
  idusuarios: number;
  idtipousuario: number = 0;
  usuario: string = "";
  senha: string = "";
  senha1: string = "";
  nome: string = "";
  habilitado: boolean = true;
}

export class TipoUsuarioModel {
  idtipousuario: number;
  descricao: string;
}