export class JogosModel {
  idjogos: number = 0;
  descricao: string = "";
  habilitado: boolean = false;
  Arquivo: File = null;
}

export class JogosAlunosModel {
  idjogos: number;
  idusuarios: number;
  concluiu: boolean;
  sucesso: boolean;
}

export class JogosAlunosDetalhesModel {
  idjogos: number;
  descricao: string;
  concluiu: boolean;
  sucesso: boolean;
  IniciadoEm: string;
  ConcluidoEm: string;
}