import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { UsuarioModel } from '../usuarios/usuariosModel';
import { LoginModel } from './loginModel';

@Injectable()
export class LoginService {

  Rota: String = this.globalFunc.APIUrl() + "/apiftp";

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  Login(formLogin: LoginModel) {
    return this.http.post<UsuarioModel>(`${this.Rota}/Login`, formLogin)
    .toPromise()
    .then(response => <UsuarioModel>response)
    .then(data => {
      return <UsuarioModel>data['response'][0];
    });
  }


}
