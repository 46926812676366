import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AulasComponent } from './aulas/aulas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { LoginService } from './login/loginService';
import { HttpClientModule } from '@angular/common/http';
import { GlobalFunctions } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JogosComponent } from './jogos/jogos.component';
import { PrincipalComponent } from './principal/principal.component';
import { UsuariosService } from './usuarios/usuariosService';
import { TableModule } from 'primeng/table';
import { JogosService } from './jogos/jogosService';
import { AulasService } from './aulas/aulasService';
import { PlayerComponent } from './player/player.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AulasComponent,
    UsuariosComponent,
    DashboardComponent,
    JogosComponent,
    PrincipalComponent,
    PlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
  ],
  providers: [
    GlobalFunctions,
    LoginService,
    UsuariosService,
    JogosService,
    AulasService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
