import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { GlobalFunctions } from 'src/environments/environment';
import { AulasService } from './aulasService';
import { JogosAlunosDetalhesModel, JogosAlunosModel } from '../jogos/jogosModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aulas',
  templateUrl: './aulas.component.html',
  styleUrls: ['./aulas.component.css']
})
export class AulasComponent implements OnInit {

  IdUsuario: number = parseInt(localStorage.getItem("idusuarios"));
  State: number = 1;
  MenuOpen: boolean = false;
  GamesAluno: JogosAlunosDetalhesModel[];
  GamesConcluidos: number = 0;
  AlunoLevel: number = 0;
  Acheivements: any;
  AlunoName: string = localStorage.getItem("name");

  constructor(
    private globalFunc: GlobalFunctions,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
    private AulasService: AulasService,
    private router: Router,
  ) { }

  ngOnInit() {
    if(!localStorage.getItem("idusuarios")){
      this.router.navigate(['/']);
    }
    
    this.AulasService.GetAllJogosAluno(this.IdUsuario).then(resUser => {
      console.log(resUser)
      this.GamesAluno = resUser;
      this.GetUserLevel();
    });

    this.globalFunc.chamaScript(this.document, this.elementRef, "lottieScript.js", "Lottie");    

    window.addEventListener("resize", () => {
      let menu = document.querySelector(".menu-content") as HTMLElement;

      if(window.innerWidth > 1024) {
        if(menu === null) {

        } else {
          this.MenuOpen = false;
          menu.removeAttribute("style");
        }
      }
    }); 
  }

  MenuHambOpen() {
    let menu = document.querySelector(".menu-content") as HTMLElement;

    if(this.MenuOpen === false){
      menu.style.marginBottom = "0rem";
      this.MenuOpen = true;
    } else if(this.MenuOpen === true){
      menu.style.marginBottom = "-21rem";
      this.MenuOpen = false;
    }
  }

  CloseHamb() {
    let menu = document.querySelector(".menu-content") as HTMLElement;

    if(window.innerWidth <= 1024){
      menu.style.marginBottom = "-21rem";
      this.MenuOpen = false;
    }
  }

  EntrarAula(i) {
    let formJogo: JogosAlunosModel = new JogosAlunosModel();
    formJogo.idjogos = i;
    formJogo.idusuarios = this.IdUsuario;
    this.AulasService.CriarJogoAluno(formJogo).then(response => {
      this.router.navigate(['aulas/player/' + i]);
    })
  }

  ChangeStage(stage) {
    this.State = stage;
  }

  GetUserLevel() {
    for(let i = 0; i < this.GamesAluno.length; i++) {
      if(this.GamesAluno[i].concluiu === <any>1) {
        this.GamesConcluidos = this.GamesConcluidos + 1
      }
    }
    this.AlunoLevel = this.GamesConcluidos / 2;
    this.AlunoLevel = Math.floor(this.AlunoLevel);

    this.CreateAcheivements(this.AlunoLevel, this.GamesConcluidos);
  }
  
  CreateAcheivements(level, games) {
    this.Acheivements = [
      {
        "title": "COMPLETE YOUR FIRST GAME",
        "level": 1,
        "type": 1,
        "require": 1,
        "completed": 0,
      },
      {
        "title": "REACH LEVEL 2",
        "level": 1,
        "type": 2,
        "require": 2,
        "completed": 0,
      },
      {
        "title": "PLAY 5 GAMES",
        "level": 1,
        "type": 1,
        "require": 5,
        "completed": 0,
      },
      {
        "title": "REACH LEVEL 5",
        "level": 2,
        "type": 2,
        "require": 5,
        "completed": 0,
      },
      {
        "title": "PLAY 10 GAMES",
        "level": 2,
        "type": 1,
        "require": 10,
        "completed": 0,
      },
      {
        "title": "REACH LEVEL 7",
        "level": 3,
        "type": 2,
        "require": 7,
        "completed": 0,
      },
      {
        "title": "PLAY 15 GAMES",
        "level": 3,
        "type": 1,
        "require": 15,
        "completed": 0,
      },
      {
        "title": "REACH LEVEL 10",
        "level": 4,
        "type": 2,
        "require": 10,
        "completed": 0,
      },
      {
        "title": "PLAY 20 GAMES",
        "level": 4,
        "type": 1,
        "require": 20,
        "completed": 0,
      },
    ];

    for(let i = 0; i < this.Acheivements.length; i++) {
      if(this.Acheivements[i].type === 1) {
        if(this.Acheivements[i].require <= games) {
          this.Acheivements[i].completed = 1;
        }
      } else if(this.Acheivements[i].type === 2) {
        if(this.Acheivements[i].require <= level) {
          this.Acheivements[i].completed = 1;
        }
      }
    }
  }

  OpenModal() {
    let exitCont = document.querySelector(".exit-container") as HTMLElement;
    let exitModal = document.querySelector(".exit-modal") as HTMLElement;

    exitCont.style.display = "flex";
    setTimeout(() => {
      exitCont.style.opacity = "1";
      setTimeout(() => {
        exitModal.style.transform = "scale(1.1)"
        setTimeout(() => {
          exitModal.style.transform = "scale(1)"
        }, 200);
      }, 400);
    }, 50);
  }

  Exit(exit) {
    let exitCont = document.querySelector(".exit-container") as HTMLElement;
    let exitModal = document.querySelector(".exit-modal") as HTMLElement;

    if(exit === 1) {
      this.router.navigate(['/']);
      localStorage.clear();
    } else if(exit === 2){
      exitModal.style.transform = "scale(1.1)";
      setTimeout(() => {
        exitModal.style.transform = "scale(0)";
        setTimeout(() => {
          exitCont.style.opacity = "0";
          setTimeout(() => {
            exitCont.style.display = "none";
          }, 350);
        }, 400);
      }, 300);
    }
  }

}
